import GTable from "@/components/table";
import http from "@/http";
import {formatDecimal} from "@/resources/util";

const columns = [
    {
        title: 'ID',
        key: 'id',
        width: '100px',
        align: 'center',
        dataIndex: 'id',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
    },
    {
        title: 'Nome',
        width: '35%',
        dataIndex: 'nome'
    },
    {
        title: 'Categoria',
        dataIndex: 'categoria.descricao',
        ellipsis: true,
        width: '20%',
        scopedSlots: { customRender: 'categoria' }
    },
    {
        title: 'Valor',
        width: '15%',
        dataIndex: 'valor',
        sorter: (a, b) => a.valor - b.valor,
        customRender: (value, record) => !value ? 'R$ 0,00' : `R$ ${formatDecimal(value)}${record.unidade_medida === 'M' ? ` (m2)` : ''}`,
    },
    {
        title: 'Descrição',
        width: '40%',
        ellipsis: true,
        dataIndex: 'descricao',
        scopedSlots: { customRender: 'descricao' }
    }
];

export default {

    data() {
        return {

            list: [],
            loading: null,
            visibleInfo: false,
            pagination: {

                showSizeChanger: true, current: 1,
                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} itens`,
                onChange: (page) => this.pagination.current = page
            },
        }
    },
    created() {

        this.load();
    },
    methods: {

        load() {

            this.loading = true;
            http.get('painel/financeiro.listarCotacao').then(({ data }) => {

                this.list = data.items;

            }).catch(err => {

                this.$error({
                    title: 'Desculpe, houve um erro!',
                    content: err.message,
                });

            }).finally(() => this.loading = false)
        }
    },
    render() {

        const table = {

            props: {

                columns,
                rowKey: 'id',
                dataSource: this.list,
                pagination: this.pagination,
                loading: this.loading,
                scroll: { x: 'max-content' }
            },
            scopedSlots: {

                categoria: (text) => !text ? <i>Não informado</i> : text,
                descricao: (text) => !text ? <i>Não informado</i> : text
            }
        };

        return (

            <div class="view view-body-scrollable">
                <div class="view-header">
                    <div class="view-header-body" style='padding: 16px 14px'>
                        <div class="view-header-start">
                            <g-icon icon={['fas', 'dollar-sign']} fixed-width/>
                            <h1 style='font-size: 26px'>Cotação de Preços</h1>
                        </div>
                        <i style="margin-left: 12px; color: red;">*Os valores podem sofrer alterações, entre em contato para saber mais.</i>
                    </div>
                </div>
                <GTable {...table} />
            </div>
        )
    }
}
